export const CarouselMock = [
    {
        nickName: 'GOR4666',
        title: 'Warface',
        price: '6000.00₽',
        discription: 'Всё происходит очень быстро. Все продавцы очень вежливы и пытаются сделать всё как можно лучше. Всем советую)'
    },
    {
        nickName: 'lwhite',
        title: 'CS: Global Offensive',
        price: '5100.00₽',
        discription: 'Очень хороший сервис продаж, всем рекомендую, сам продаю на ней больше года, поддержка топ, покупатели и продавцы лучшие!'
    },
    {
        nickName: 'SmartiOxigenium',
        title: 'World Of Warcraft',
        price: '8000.00₽',
        discription: 'Минусов нету, всё очень удобно, быстрая и вежливая тех. поддержка. Всё честно и быстро. Рекомендую!'
    },
    {
        nickName: 'Artuazzz',
        title: 'Lineage 2',
        price: '6400.00₽',
        discription: 'Пользуюсь услугами продолжительное время. Было дело что покупал/продавал аккаунты, было дело что покупал/продавал золото. Всё отлично и всём советую.'
    },
    {
        nickName: 'GOST426',
        title: 'Ражабик',
        price: '9300.00₽',
        discription: 'Отличный проверенный сайт для продажи игрового имущества и т. д, сам пользуюсь и вам советую, это вам не какой-то ноунейм сайт где вас кинут и пошлют подальше.'
    },
    {
        nickName: 'GENA1993',
        title: 'Warface',
        price: '4000.00₽',
        discription: 'Всё происходит очень быстро. Было дело что покупал/продавал аккаунты. Всем советую)'
    },
    {
        nickName: 'SkingerLoy',
        title: 'Канал Telegram',
        price: '20000.00₽',
        discription: 'Со стороны покупателя площадка максимально доступна и понятна, а главное по возможности защищена, 100% Рекомендую! Площадка свои роль в сделке выполняет.'
    },
    {
        nickName: 'Samvvediokda',
        title: 'Aion',
        price: '12000.00₽',
        discription: 'Пользуюсь от случая к случаю, покупаю иногда всякую фигню в играх или аккаунты. Выбирал всегда аккуратно продавцов и ни разу не попал на кидал.'
    },
    {
        nickName: 'Jeka05Andy',
        title: 'Lost Ark',
        price: '20000.00₽',
        discription: 'Поддержка работает очень быстро, все сотрудники понимают суть своей работы и помогают в любой ситуации. Много раз совершал покупки, все понравилось, удобный интерфейс, приятный дизайн. В общем, все на уровне.'
    }
];

